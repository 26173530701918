import * as React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

console.log("0.36.0");

const App = () => {
  const [swaggerUrl, setSwaggerUrl] = React.useState("");
  React.useEffect(() => {
    const url = new URL(window.location.href);
    if (!url.searchParams.has("deepLinking")) {
      url.searchParams.append("deepLinking", "true");
      window.location.href = url.href;
    }
    const isInternal =
      window.location.hostname.includes("staging") &&
      url.searchParams.has("internal") &&
      url.searchParams.get("internal") === "true";

    setSwaggerUrl(
      window.location.hostname.includes("stagingv35-apidoc.testrtc.com")
        ? isInternal
          ? "/swagger_st_v35_internal.json"
          : "/swagger_st_v35.json"
        : window.location.hostname.includes("staging-apidoc.testrtc.com")
        ? isInternal
          ? "/swagger_st_internal.json"
          : "/swagger_st.json"
        : window.location.hostname.includes("staging1-apidoc.testrtc.com")
        ? isInternal
          ? "/swagger_st1_internal.json"
          : "/swagger_st1.json"
        : window.location.hostname.includes("api1doc.testrtc.com")
        ? "/swagger1.json"
        : window.location.hostname.includes("localhost")
        ? "/swagger_dev.json"
        : "/swagger.json"
    );
  }, []);

  return <SwaggerUI url={swaggerUrl} docExpansion="list" />;
};

export default App;
